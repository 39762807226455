import React from 'react';
import '@styles/reset.css';
import '@styles/global.scss';
import '@styles/variables.scss';

const Page = ({ children }) => (
  <div className="Page">
    {children}
  </div>
);

export default Page;