import React from 'react';
import TelIcon from '@images/phone-icon.svg';
import './styles.scss';

const ContactCardTelephone = ({
  title,
}) => (
    <div className="ContactCardTelephone">
      <a href="tel:+385992885442" className="ContactCardTelephone-main-action">
        <div className="ContactCardTelephone-icon">
          <img alt="Phone" src={TelIcon} />
        </div>
        <div className="ContactCardTelephone-title">
          {title}
        </div>
      </a>
    </div>
  );

export default ContactCardTelephone;