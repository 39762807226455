import React, { useState } from 'react';
import { Link } from 'gatsby';
import { Container } from '@components';
import Hamburger from './components/Hamburger';
import LogoImagePath from '@images/dreamfactory-logo.svg';
import './styles.scss';

const Header = () => {
  const [isOpen, toggleOpen] = useState(false);
  return (
    <Container>
      <header className="Header">
        <div>
          <Link to="/" onClick={isOpen ? () => toggleOpen(false) : undefined}>
            <img
              alt="Dream Factory logo"
              height={80}
              src={LogoImagePath}
            />
          </Link>
        </div>
        <nav className={isOpen ? 'menu-open' : ''}>
          <Link to="/#services" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>Services</Link>
          <Link to="/#about" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>About us</Link>
          <Link to="/#careers" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>Careers</Link>
          <Link to="/#contact" onClick={isOpen ? () => toggleOpen(false) : undefined} data-scroll>Contact</Link>
        </nav>
        <Hamburger
          isOpen={isOpen}
          toggleOpen={() => toggleOpen(!isOpen)}
        />
      </header>
    </Container>
  );
};

export default Header;