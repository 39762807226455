import React, { useState } from 'react';
import './styles.scss';

const Input = ({
  error,
  handleChange,
  inputId,
  name,
  label,
  type,
  ...rest
}) => {
  const [hasValue, setValue] = useState('')
  return (
    <div className="Input">
      <input
        id={inputId}
        className={`${hasValue ? 'hasValue' : ''} ${error ? 'error' : ''}`}
        type={type}
        onChange={e => {
          setValue(e.target.value);
          handleChange(e.target.value);
        }}
        name={name}
        {...rest}
      />
      <label
        htmlFor={inputId}
      >
        {label}
      </label>
    </div>
  );
};

export default Input;