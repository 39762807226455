import React from 'react';
import './styles.scss';

const Container = ({ children, type }) => (
  <div className={`Container Container--${type}`}>
    {children}
  </div>
);

Container.defaultProps = {
  type: 'default'
};

export default Container;