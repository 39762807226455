import React, { useState } from 'react';
import { Button, Input, LoadingIndicator } from '@components';
import MailIcon from '@images/mail-icon.svg';
import './styles.scss';

export const emailRegex = /^[a-z0-9!#$%&'*+/=?\^_`{|}~\-]+(?:.[a-z0-9!#$%&'*+/=?\^_`{|}~\-]+)*@(?:[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/i;

const ContactCardMailForm = ({
  title,
}) => {
  const [isFormDisplayed, setFormDisplay] = useState(false);
  const [isSubmiting, setSubmiting] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [description, setDescription] = useState('');

  const handleSubmit = () => {
    const isEmailValid = emailRegex.test(email);

    setSubmiting(true);

    if (!isEmailValid) {
      setEmailError(true);
      setSubmiting(false);
      return;
    }

    setEmailError(false);
    setSubmiting(false);

    const data = {
      email: email,
      name: name,
      description: description,
    };

    console.log(data);
  };

  const handleFormDisplay = () => {
    setFormDisplay(!isFormDisplayed);
    setName('');
    setEmail('');
    setDescription('');
  }

  return (
    <div className="ContactCardMailForm">
      <button
        className="ContactCardMailForm-main-action"
        // onClick={() => handleFormDisplay()}
        onClick={() => window.location.href = 'mailto:info@dream-factory.hr'}
      >
        <div className="ContactCardMailForm-icon">
          <img alt="Email" src={MailIcon} />
        </div>
        <div className="ContactCardMailForm-title">
          {title}
        </div>
      </button>
      {
        isFormDisplayed && (
          <form
            className="ContactForm"
            onSubmit={e => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <Input
              inputId="name-input"
              name="name"
              type="text"
              label="Name"
              value={name}
              handleChange={val => setName(val)}
            />
            <Input
              inputId="email-input"
              name="email"
              type="email"
              label="E-mail"
              required
              error={emailError}
              value={email}
              handleChange={val => setEmail(val)}
            />
            <Input
              inputId="description-input"
              name="description"
              type="text"
              label="Tell us about your project"
              value={description}
              handleChange={val => setDescription(val)}
            />
            <Button
              type="submit"
            >
              {
                isSubmiting ? (
                  <LoadingIndicator />
                ) : 'Submit'
              }
            </Button>
          </form>
        )
      }
    </div>
  );
};

export default ContactCardMailForm;