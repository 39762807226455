import React from 'react';
import './styles.scss';

const ServicesCard = ({
  title,
  text,
}) => (
  <div className="ServicesCard">
    <div className="ServicesCard-title">
      {title}
    </div>
    <div className="ServicesCard-text">
      {text}
    </div>
  </div>
);

export default ServicesCard;