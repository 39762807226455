import React from 'react';
import { Link } from 'gatsby';
import Logo from '@images/dreamfactory-logo-dark.svg';
import './styles.scss';

const Footer = () => (
  <footer className="Footer">
    <img src={Logo} alt="DreamFactory logo dark" height="180px" />
    <p>
      <a
        href="https://www.google.com/maps/place/Ul.+Ivana+Zajca+6,+23000,+Zadar/@44.1248819,15.2322233,17z/data=!4m5!3m4!1s0x4761fa76208519cd:0x6fc93469ba29c291!8m2!3d44.1249966!4d15.2324074"
        rel="noopener noreferrer"
        target="_blank"
      >
        Ivana Zajca 6, 23000 Zadar, Croatia
      </a>
    </p>
    <p>
      <a href="tel:+385992485442">
        +385 99 288 5442
      </a>
    </p>
    <p>
      <a href="mailto:info@dream-factory.hr">
        info@dream-factory.hr
      </a>
    </p> 
    <hr/>
    <nav>
      <Link to="/privacy-policy">
        Privacy policy
      </Link>
      <Link to="/legal">
        Legal
      </Link>
    </nav>
    <p>
      &copy; DreamFactory. All rights reserved.
    </p>
  </footer>
);

export default Footer;